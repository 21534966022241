import moment from "moment";

/*
 *  Self-invoking function with the purpose of handling the countdown functionality.
 */

(() => {
  const timestamp = 1711460378;
  const genesis = moment.unix(timestamp);
  const countdown = document.getElementById("countdown");

  const assignTime = () => {
    const present = moment().unix();
    const counter = genesis.valueOf() / 1000 - present;
    const duration = moment.duration(counter, "seconds");

    if (duration.isValid() && duration.asSeconds() >= 0) {
      const days = Math.floor(duration.asDays());

      const formatters = [
        ["days", days > 9 ? days : `0${days}`],
        ["hours", "HH"],
        ["minutes", "mm"],
        ["seconds", "ss"],
      ];

      const format = (key) =>
        moment
          .utc(moment.duration(counter, "seconds").asMilliseconds())
          .format(`${key}`);

      const values = formatters.reduce(
        (total, [label, key]) =>
          Object.assign(total, {
            [label]: format(key),
          }),
        {}
      );

      formatters.forEach(([identifier]) => {
        Object.assign(document.getElementById(identifier), {
          innerText: values[identifier],
        });
      });
    }
  };

  if (countdown) {
    const updateTimerInterval = setInterval(() => {
      if (genesis.valueOf() / 1000 > moment().unix()) {
        assignTime();
      } else {
        clearInterval(updateTimerInterval);
      }
    }, 1000);

    assignTime();
  }
})();
